import React from "react";
import "../../App.css";
import Footer from "../Footer";
import { Link } from "react-router-dom";

export default function Services() {
  return (
    <>
      <div className="services-container">
        <div className="packages-intro">
          {/* <img src="\images\ReelFocusMedia_White.png" alt="" /> */}
          <h1>Services</h1>
          <div className="services-intro">
            <p>
              Reel Focus Media provides a wide variety of videography services.
              Wedding packages can be found below as well as examples of our
              services covering business events. More packages to come! Do not
              hesitate to contact us to discuss any and all of your upcoming
              events or projects. Weddings, Events, Podcasts we can do it all!
            </p>
          </div>
          <div className="weddings-business">
            <div className="image-container">
              <Link to="/weddings" className="service-links">
                <img
                  className="wedding-image"
                  src="/images/jeremy-wong-weddings-464ps_nOflw-unsplash.jpg"
                  alt="Click me"
                />
                <div className="image-text">Weddings</div> {/* Add text */}
              </Link>
            </div>

            <div className="image-container">
              <Link to="/businessevents" className="service-links">
                <img
                  className="business-image"
                  src="/images/courtney-cook-jxEXrF3L97s-unsplash.jpg"
                  alt="Click me"
                />
                <div className="image-text">Business Events</div>{" "}
                {/* Add text */}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="wedding-packages-button"></div>
      <div className="package-questions">
        <p>
          Please reach out to us to discuss any needed alterations or addons for
          any of our available packages.
        </p>
        <br />
        <p>Your vision is our priority!</p>
        <hr></hr>
        <a href="/sign-up" className="contact-us-button">
          CONTACT US
        </a>
        <p>Call or text us: 909-762-2159</p>
      </div>
      <Footer />
    </>
  );
}
